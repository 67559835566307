import React, { useRef, useState, useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'
// UTILS
import { SECTION_TOP_PADDING, SECTION_BOTTOM_PADDING } from '../utils/constants'
// Types
import { PageContextProps, PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
import { ArgumentProps, HubspotFormProps, TypeformFormProps } from '../types/blocks'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'
import { Flex, Box, Heading, Image } from '../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import HubspotForm from '../components/commons/HubspotForm'
import { StaticImage } from 'gatsby-plugin-image'
import TypeformForm from '../components/commons/TypeformForm'

interface ContactQuery {
  contact: {
    pageInfo: PageInfoProps
    meta: PublicationProps
    headline: string
    form: HubspotFormProps | TypeformFormProps
    argumentList: ArgumentProps[]
    sections: SectionBase[]
  }
}

const Contact: React.FC<PageProps<ContactQuery, PageContextProps>> = ({ data, pageContext }) => {
  const {
    contact: { meta, pageInfo, headline, form, argumentList, sections },
  } = data

  const titleRef = useRef<HTMLDivElement | null>(null)
  const [contentMargin, setContentMargin] = useState(0)

  useEffect(() => {
    if (titleRef.current) {
      setContentMargin(titleRef.current?.offsetHeight)
    }
  }, [titleRef])

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={pageInfo.hidePrefooter}
    >
      <Seo
        slug={pageInfo.slug}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
        preventIndexing={pageInfo.preventIndexing}
      />

      <Box position={'relative'}>
        <Box
          display={['none', 'none', 'block']}
          position={'absolute'}
          top={0}
          right={0}
          width={'50%'}
          height={'100%'}
          zIndex={0}
          backgroundColor={'darkBlue'}
          overflow={'hidden'}
        >
          <Box display={['none', 'none', 'block']} position={'absolute'} right={'-80px'} bottom={'-80px'} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../images/grid.png'}
              alt={''}
              placeholder={'none'}
              width={247}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} bottom={-180} left={-150} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../images/ellipse_violet_btr.png'}
              alt={''}
              placeholder={'none'}
              width={436}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} top={0} right={-140} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../images/ellipse_violet_tbr.png'}
              alt={''}
              placeholder={'none'}
              width={436}
            />
          </Box>
        </Box>
        <Box position={'relative'} zIndex={1}>
          <Grid>
            <Row center={'xs'} between={'md'}>
              <Col xs={12} sm={10} md={6} lg={5}>
                <Box pt={SECTION_TOP_PADDING} pb={SECTION_BOTTOM_PADDING}>
                  <Box ref={(node) => (titleRef.current = node)} pb={[6, 7, 8]}>
                    <Heading as={'h1'} type={'heading1'}>
                      {headline}
                    </Heading>
                  </Box>

                  {form && (
                    <Box>
                      {form.model.apiKey === 'hubspot_form' && <HubspotForm {...(form as HubspotFormProps)} />}
                      {form.model.apiKey === 'typeform_form' && (
                        <TypeformForm format={'vertical'} {...(form as TypeformFormProps)} />
                      )}
                    </Box>
                  )}
                </Box>
              </Col>
              {/* DESKTOP */}
              <Col xs={false} md={6} lg={5}>
                <Box
                  pb={SECTION_BOTTOM_PADDING}
                  position={'sticky'}
                  top={'calc(110px + var(--banner-height))'}
                  pl={[0, 0, 5, 0]}
                >
                  {argumentList.length > 0 && (
                    <Box mt={contentMargin + 130}>
                      {argumentList.map((arg, i) => (
                        <Flex key={arg.id} alignItems={'center'} mb={i + 1 < argumentList.length ? 5 : undefined}>
                          <Box
                            mr={6}
                            flex={'0 0 80px'}
                            borderRadius={'small'}
                            backgroundColor={'rgba(255,255,255,0.15)'}
                          >
                            <Image alt={arg.icon.alt || arg.headline} image={arg.icon.gatsbyImageData} />
                          </Box>
                          <Box flex={'1'}>
                            <Heading as={'h4'} type={'heading4'} color={'white'}>
                              {arg.headline}
                            </Heading>
                          </Box>
                        </Flex>
                      ))}
                    </Box>
                  )}
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>

        {/* MOBILE */}
        <Box display={['block', 'block', 'none']} backgroundColor={'darkBlue'}>
          <Grid>
            <Row center={'xs'}>
              <Col xs={12} sm={10}>
                <Box py={8}>
                  {argumentList.length > 0 && (
                    <Box>
                      {argumentList.map((arg, i) => (
                        <Flex key={arg.id} alignItems={'center'} mb={i + 1 < argumentList.length ? 5 : undefined}>
                          <Box
                            mr={6}
                            flex={'0 0 80px'}
                            borderRadius={'small'}
                            backgroundColor={'rgba(255,255,255,0.15)'}
                          >
                            <Image alt={arg.icon.alt || arg.headline} image={arg.icon.gatsbyImageData} />
                          </Box>
                          <Box flex={'1'}>
                            <Heading as={'h4'} type={'heading4'} color={'white'}>
                              {arg.headline}
                            </Heading>
                          </Box>
                        </Flex>
                      ))}
                    </Box>
                  )}
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>
      </Box>

      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery($locale: String!) {
    contact: datoCmsContact(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      headline: formHeadline
      form {
        ...hubspotFormFragment
        ...typeformFormFragment
      }
      argumentList: arguments {
        ...argumentFragment
      }
      sections {
        ...sectionFAQFragment
        ...sectionLogoListFragment
      }
    }
  }
`

export default Contact
